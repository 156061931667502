/* Custom CSS for Product Page */

.product-section {
  padding: 20px;
  display: flex;
  flex-direction: column; /* Change to column layout on smaller devices */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  margin: 10px;
}

/* Product Sidebar */
.product-sidebar {
  background-color: #f8f9fa;
  padding: 0px 10px;
  min-width: 15%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}

/* Product Mainbar */
.Product-mainbar {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  /* width: 100%; */
  padding: 0 20px;
}

.product-category-nav{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-top: solid 1px #ba9773;
  border-bottom: solid 1px #ba9773;
  background-color: #909f80;
}

.product-category-nav > *{
  margin: auto;
  color: #73383dd7;
}

.Product-mainbar .product-category-title {
  /* color: #ffffffd7; */
  /* background-color: #73383dd7; */
  padding: 10px;
  width: auto;
  /* border-radius: 10px; */
  text-align: center;

}

/* Product Categories Menu */
.product-categories-menu h1 {
  text-align: center;
  color: #73383dc1;
}

.product-categories-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.product-categories-menu li {
  margin-bottom: 10px;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: auto;
  background-color: #909f80;
  color: #f8f8f8;
  border-radius: 5px;
}

.product-categories-menu li:hover {
  background-color: white;
  color: #ba9773;
  border: solid 1px #ba9773;
}

/* Product Sub Categories Menu */
.product-sub-categories-menu {
  padding: 20px;
}

.product-sub-categories-menu ul {
  list-style-type: none;
  padding: 20px;
  margin: 0;
}

.product-sub-categories-menu li {
  margin-bottom: 20px;
  border: #0056b3 dotted 1px;
  padding: 10px;
  gap: 20px;
  border-radius: 10px;
}

.product-sub-category-title {
  background-color: #909f80;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /* color: white; */
}

/* Product Menu */
.product-menu {
  border: #0056b3 dotted 1px;
  padding: 10px;
}

.product-menu-search-filter {
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: baseline;
  justify-content: space-between;
}

.product-search-item{
  width: 100%;
}

/* Add media query for small screens */
@media screen and (max-width: 768px) {
  .product-menu-search-filter {
    flex-direction: column; /* Change to column layout on small screens */
    align-items: stretch; /* Stretch items to fill container width */
  }

  .product-menu-search-filter select {
    width: 100%; /* Make selects fill container width */
  }

  .product-sub-categories-menu{
   padding: 0px;
   width: inherit;
  }
}


/* Product List Container */
.product-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Product Card */
.product-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
}

.product-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Product Image */
/* .product-image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
} */

/* Product Image */
.product-image img {
  width: 100%; /* Ensure the image fills the container */
  height: 300px; /* Set a fixed height for the images */
  object-fit: cover; /* Ensure the entire image is visible and covers the container */
  border-radius: 5px;
  margin-bottom: 10px;
}


/* Product Content */
.product-content h3 {
  margin-top: 0;
}


/* Product Buttons */
/* Product Buttons */
.product-btns {
  display: flex;
  justify-content: space-between;
}
.product-btns button {
  width: 100%;
  padding: 5px 15px;
  border: none;
  background-color: #909f80;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.product-btns button:hover {
  background-color: #0056b3;
}

/* Product Pagination */
.product-pagenation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.product-pagenation button {
  margin: 0 5px;
}


/* Add media query for smaller devices */
@media screen and (max-width: 768px) {
  .product-pagenation button {
    margin: 0 2px; /* Adjust margin for smaller screens */
  }

  .product-image img {
    height: auto;
  }
}
.product-preview-container {
  display: flex;
  /* flex-wrap: wrap; Allows items to wrap on smaller screens */
  justify-content: space-between; /* Adjusts the space between the two containers */
  padding: 20px;
  gap: 20px; /* Adjusts the gap between the image slider and the description */
  border: #0056b3 solid 1px;
  margin: 10px;
}

.product-image-slider {
  position: relative;
  width: 50%; /* Adjust this to control the width of the image slider */
}

.product-slide-window {
  display: flex;
  overflow: hidden;
  position: relative;
}

.product-slide {
  min-width: 100%;
  transition: transform 0.5s ease-in-out;
}
/* Product Image */
.product-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
  display: block;
  border-radius: 10px;
  aspect-ratio: 4 / 3; /* Ensures all images maintain a 4:3 aspect ratio */
  cursor: pointer;
}


button.prev, button.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

button.prev {
  left: 10px;
}

button.next {
  right: 10px;
}
/* Add this to your CSS file (styles.css) */

/* General styles */
.product-info-description {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: #0056b3 solid 1px;
  border-radius: 10px;
  padding: 20px;
  font-family: 'Arial', sans-serif; /* Change to a more appealing font */
  line-height: 1.6;
  text-align: justify; /* Justify the text */
}

.product-content-section h2 {
  font-family: 'Georgia', serif;
  font-size: 1.8rem; /* Adjust the size as needed */
  margin-bottom: 10px;
}

.product-content-section p {
  font-family: 'Arial', sans-serif;
  font-size: 1rem; /* Adjust the size as needed */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .product-info-description {
    width: 100%; /* Full width on smaller screens */
    margin-top: 20px; /* Add some space between the slider and description */
    padding: 15px;
  }
}

.product-action-btns button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
}

.product-action-btns button:hover {
  background-color: #0056b3;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .product-preview-container {
    flex-direction: column;
    align-items: center;
  }

  .product-image-slider,
  .product-info-description {
    width: 100%; /* Full width on smaller screens */
  }

  .product-info-description {
    margin-top: 20px; /* Add some space between the slider and description */
  }
}

@media (max-width: 480px) {
  .product-action-btns button {
    padding: 10px; /* Adjust button padding for smaller screens */
  }
}

/* Media Query for Larger Devices */
@media (min-width: 769px) {
  .product-preview-container {
    flex-direction: row;
  }

  .product-image-slider {
    width: 50%;
  }

  .product-info-description {
    width: 50%;
  }
}
/* styles.css */

/* General styles */
.cart-section {
  background-color: #f9f9f9;
  padding: 40px 20px;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  justify-content: space-around;
  gap: 20px;
}

.cart-item img {
  max-width: 200px;
  height: auto;
  margin-right: 20px;
}

.cart-item-details {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-control > *{
  /* background-color: #007bff; */
  border: solid 1px #007bff;
  padding: 20px;
  font-size: 10px;
  color: #043467;
  cursor: pointer;
}

.checkout-delete{
  background-color: rgb(211, 36, 36);
  color: white;
  border: none;
}

.checkout-delete:hover{
  background-color: white;
  color: rgb(211, 36, 36);
  border: solid rgb(211, 36, 36) 1px;
}

.total-price {
  text-align: right;
  font-size: 1.5rem;
  margin-top: 20px;
}

.checkout-button {
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background-color: #45a049;
}

.cart-page-navigation{
  display: flex;
  justify-content: space-between;
  gap: 10px;
}



/* Responsive styles */
@media only screen and (max-width: 768px) {
  .cart-item {
    flex-direction: column;
    align-items: center;
  }
  
  .cart-item img {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .cart-item-details {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .total-price {
    font-size: 1.2rem;
  }
  
  .checkout-button {
    margin-top: 20px;
  }
  .cart-page-navigation{
flex-direction: column;
  }
  
}
/* styles.css */

.close-cart-button {
  position: absolute;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333; /* Change the color as needed */
  padding: 0;
}

.close-cart-button:hover {
  color: #f00; /* Change the hover color as needed */
}

/* styles.css */

.checkout-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.checkout-page h2 {
  margin-bottom: 20px;
}

.checkout-page p {
  margin-bottom: 10px;
}

.payment-method-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.payment-method-buttons button {
  display: flex;
  align-items: center;
}

.payment-method-buttons button svg {
  margin-right: 5px;
  color: #333; /* Default color for icons */
}

.preview-page-navigation{
  /* border: #0056b3 solid 2px; */
  padding: 10px;
  margin-bottom: 40px;
}

/* Customize the color for each icon */
.payment-method-buttons button .fa-money-bill-wave {
  color: #007bff; /* Blue color for Cash on Delivery */
}

.payment-method-buttons button .fa-mobile {
  color: #28a745; /* Green color for Lipa na M-Pesa */
}

.payment-method-buttons button .fa-paypal {
  color: #ff3f3f; /* Red color for PayPal */
}

.checkout-button {
  margin-top: 20px;
  width: 100%;
}

/* Indicators Container */
.indicators {
  display: flex;
  justify-content: center; /* Center the pagination dots */
  align-items: center;
  margin-top: 20px;
}

/* Indicators Dot */
.indicators-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Active Indicator Dot */
.indicators-dot.active {
  background-color: #007bff; /* Change the color for the active dot */
}

/* Hover Effect for Indicators Dot */
.indicators-dot:hover {
  background-color: #0056b3;
}

/* Adjust margin for smaller screens */
@media screen and (max-width: 768px) {
  .indicators-dot {
    margin: 0 2px;
  }
}
