/* src/components/LoginForm.css */
/* @import url('https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&display=swap'); */
.login-background-container {
  /* background: url('../components/publicpage/videos/biashara-hub.gif') center/cover fixed; */
  background-color: #909f80;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-family: "Lancelot", serif;
  font-weight: 400;
  font-style: normal;
}

.login-form-wrapper {
  background: rgba(255, 255, 255, 0.149);
  border-radius: 10px;
  overflow: hidden;
  /* box-shadow: 0 0 5px rgba(211, 161, 98, 0.7); */
  border: solid 1px white;
  width: 100%;
  max-width: 600px;
  color: #ffffff;
}

.login-form {
  padding: 20px;
}

.logo {
  display: block;
  margin: 0 auto 20px;
  width: 100%;
  border-radius: 150px;
  border-radius: 2000px;
  /* box-shadow: 0 0 40px rgba(213,183,104, 0.9); */
}

.login-form .form-group {
  margin-bottom: 15px;
}

.login-label {
    display: block;
    margin-bottom: 5px;
  }
  
 .login-input {
    width: 100%;
    padding: 14px;
    box-sizing: border-box;
    border: 1px solid #ffffff;
    border-radius: 6px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.5)50%, rgba(0, 0, 0, 0.5)50%);
    color: #ddd5d0;
    font-size: 14px;
    font-family: "Lancelot", serif;
    font-weight: 400;
    font-style: normal;
    box-shadow: 0 0 10px rgba(213,183,104, 0.9);
  }

  .login-input option{
    color: rgb(237, 143, 143);
  }
  
  .login-button {
    width: 100%;
    padding: 14px;
    /* background: rgba(0, 0, 0, 0.7); */
    background-color: #ddd5d0;
    /* border: 1px solid #d5b768; */
    color: #2e2525;
    border-radius: 6px;
    cursor: pointer;
    font-size: larger;
    font-family: "Lancelot", serif;
    font-weight: 400;
    font-style: normal;
    /* box-shadow: 0 0 10px rgba(213,183,104, 0.5); */
  }
  
  .login-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
    color: rgb(255, 255, 255);
    border: 1px solid #ffffff;
  }

  .error-message{
    color: rgb(231, 159, 159);
  }

  .login-nav-buttons{
    /* border: solid 2px white; */
    padding: 7px;
    display: flex;
    justify-content: space-between;
    gap:10px;
    /* background-color: rgba(231,218,233, 0.7); */
    border: #ffffff solid 1px;

  }

  .login-nav-buttons > *{
    background-color: rgba(0, 0, 0, 0.7);
    /* background-color: rgb(127,127,139); */
    text-align: center;
    border: none;
    margin: auto;
    padding: 16px;
    color: #ffffff;
    border-radius: 6px;
    cursor: pointer;
    font-size: larger;
    font-family: "Lancelot", serif;
    font-weight: 400;
    font-style: normal;
    box-shadow: 0 0 10px rgba(213,183,104, 0.5);
    width: 50%;
  }
  
@media (max-width: 768px) {
  .login-form-wrapper {
    width: 100%; /* Adjust the maximum width for smaller screens */
  }

  .logo {
    width: 100%; /* Adjust the width for smaller screens */
  }

  .login-nav-buttons {
    flex-direction: column;
    align-items: center; /* Align items to center horizontally */
    padding: 10px;
  }

  .login-nav-buttons > * {
    margin: 5px 0; /* Add some margin between the buttons */
    width: 95%; /* Make buttons fill the width of the container */
    text-align: center; /* Align text to center within buttons */
  }
 
}
