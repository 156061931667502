body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.navbar {
    background-color: #ffffff;
    /* color: #ba9773; */
    padding: 10px 20px;
    border-bottom: solid rgba(13, 193, 216, 0.747) 1px;
}

.top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.top-nav-pub-logo {
    flex: 1;
    display: flex;
    align-items: center; 
    justify-content: flex-start;
    cursor: pointer;

}

.top-nav-pub-logo img {
    height: 40px;
    width: auto;
    box-shadow: 0px 0px 5px rgba(13, 193, 216, 0.747);
    /* border-radius: 10px; */
}

.nav-links {
    flex: 2;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    /* border-top: 1px solid rgb(0, 0, 0); */
    /* border-bottom: 1px solid rgb(0, 0, 0); */
    box-shadow: 0px 0px 5px rgba(13, 193, 216, 0.747);
    border-radius: 10px;
}

.nav-links a {
    text-decoration: none;
    color: #2596be;
    padding: 5px 0px;
    font-size: 1.2rem;
    transition: background-color 0.3s;
}

.nav-links a.active {
    background-color: rgba(198, 27, 18, 0.819);
    color: rgb(255, 255, 255);
    padding: 5px;
    border-radius: 10px;
    text-decoration: underline; 
}

.nav-links a:hover {
    background-color: #2596be;
    /* text-decoration: underline;  */
    color: white;
    padding: 5px;  
    transition: padding-left 0.3s, background-color 0.3s;
    text-align: center;  
    border-radius: 10px;
}

.nav-links a.active:hover {
    background-color: rgba(216, 159, 10, 0.2);
    padding-left: 15px;  
}

.right-group {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.icons {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.607);
    padding: 5px;
    border-radius: 10px;
    justify-content: space-between;
}

.icons .icon {
    margin-left: 20px;
    cursor: pointer;
    position: relative;
    font-size: 20px;
    color: #2596be;
}

.icons .icon:hover {
    color: rgb(170, 26, 26);
}

.customer-account-name{
    text-decoration: none;
    color: white;
    border: 1px solid white;
    border-radius: 30px;
    padding: 7px;
    margin-left: 10px;
}

.public-title{
    text-decoration: none;
}

.customer-logout{
    text-decoration: none;
    color: white;
    /*border: 1px solid white;*/
    border-radius: 30px;
    padding: 7px;
    background: red;
    margin-left: 10px;
}

.customer-account-name:hover{
    color: cadetblue;
    background: white;
}

.customer-logout:hover{
    color: cadetblue;
    background: white;
}

#cart-count {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 12px;
}

.menu-toggle {
    background-color: transparent;
    border: none;
    color: rgb(216,159,10);
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: inherit;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;  
    /* border: solid red 2px; */
}

.modal-content {
    background-color: #434449;
    width: 100%; 
    /* max-width: 320px;   */
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    position: relative; 
    top: 200px;
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    /* border: solid red 2px; */
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 2;
}

.video-section {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;  /* 16:9 Aspect Ratio */
}

.video-section iframe, .video-section video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.bio-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0; 
}

.bio-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0; 
    background-color: #ffffff;  
    color: rgb(136, 81, 81);  
}

.bio-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px; 
    margin: 0 auto;
}

.text-description {
    flex: 1;  
    padding: 0 30px;  
    text-align: justify;  
}

.text-description h3 {
    color: #c86969;
    margin-bottom: 20px;
}

.text-description p {
    color: #c86969;
    font-size: 16px;
    line-height: 1.5;
}

.video-banner {
    flex: 1;
    padding-left: 20px;
}

.video-banner img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.607);
}

/* Add this CSS to styles.css */
.testimonial-card {
    white-space: nowrap;
    overflow: hidden;
  }
  
  .marquee {
    animation: marquee 20s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  

@media (max-width: 768px) {
    .bio-content {
        flex-direction: column;
    }

    .text-description, .video-banner {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }
}

@media (min-width: 769px) {
    .menu-toggle {
        display: none;
    }

    .top-row {
        flex-direction: row;
    }

    .top-nav-pub-logo {
        order: 1;
        margin-right: auto;
    }

    .nav-links {
        order: 2;
        margin: 0 20px;
    }

    .right-group {
        order: 3;
    }
}
@media (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .top-row {
        justify-content: space-between;
    }



    .modal .nav-links {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin: auto;
        border: none;
    }

    .modal .nav-links a {
        width: 100%;
        text-align: center;
        padding: 10px;
    }
}


.image-slider {
    position: relative;
    width: 100%;
    height: 100vh; /* You can adjust this to fit your needs, 80vh will take 80% of the viewport height */
    overflow: hidden;
}

.slide-window {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.slide-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
}

.slide {
    flex: 0 0 100%; /* makes sure each slide takes full width of the slider */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide img {
    width: 100%;
    height: auto;
    max-height: 100%;
}
.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10; /* to keep buttons above the images */
    border-radius: 50%; /* optional: makes the buttons circular */
    width: 40px;       /* to maintain a consistent size */
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

/* To adjust the icon size if needed */
.prev i, .next i {
    font-size: 16px;
}

/* To ensure the slider works well on smaller screens */
@media (max-width: 768px) {
    .image-slider {
        height: 50vh;
    }
}

.theme-month {
    color: aliceblue;
    padding: 50px 0;
    background-color: #4d44506f; /* Adjust the color as per your requirement */
}

.theme-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
}

.theme-text h3, .theme-text .slogan, .theme-text .description {
    margin-bottom: 20px;
    padding: 0px 40px;
    text-align: justify;
}

.theme-month h3{
    color: #434449;
    font-size: 2rem;
}

.theme-banner img {
    max-height: 800px;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .theme-content {
        flex-direction: column;
    }

    .theme-text, .theme-banner {
        width: 100%;
        padding: 10px 0;
        text-align: center;
    }
}
.newsletter-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 40px;
}

.newsletter-label {
    color: #434449;
    margin-bottom: 5px;
}

.input-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
}

.newsletter-input {
    flex: 3;
    padding: 10px;
    border: none;
    border-radius: 5px;
    outline: none;
}

.newsletter-submit {
    flex: 1;
    padding: 10px 20px;
    background-color: #434449;
    color: #d89f0a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.newsletter-submit:hover {
    background-color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .theme-content {
        gap: 20px; /* Providing some space between theme banner and text */
    }
    
    .newsletter-form {
        width: 70%; /* Making sure the form takes the full width */
        margin: auto;
    }

    .newsletter-label {
        text-align: center; /* Center the label text */
    }

    .input-wrapper {
        flex-direction: column;
        gap: 10px;
        width: 100%; /* Make the input-wrapper take the full width */
    }

    .newsletter-input, .newsletter-submit {
        width: 100%; /* Make both the input and button take the full width */
    }
}

.gallery-section {
    padding: 50px 5%;
    background-color: #b4c5cc;
    text-align: center;
    color: #434449;
}

.contact-section{
    border: #d35f5f solid 2px;
    margin: 20px;
}

.gallery-section h3 {
    margin-bottom: 30px;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.gallery-grid img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.view-more {
    margin-top: 30px;
    padding: 10px 30px;
    background-color: #434449;
    color: #d89f0a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.view-more:hover {
    background-color: #814c4c;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: repeat(2, 1fr); /* Two images per row */
    }
}

@media (max-width: 480px) {
    .gallery-grid {
        grid-template-columns: 1fr; /* Single image per row */
    }
}

.testimonials {
    /* background: url('images/pexels-lumn-1028600.jpg') no-repeat center center/cover; Replace with your bubble background */
    padding: 50px 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.3)50%, rgba(0, 0, 0, 0.3)50%),url('images/pexels-lumn-1028600.jpg') no-repeat center center/cover;
}

.testimonial-card {
    background: rgba(255, 255, 255, 0.1); /* This will give the frosted look */
    backdrop-filter: blur(5px); /* This blurs the background content behind the card */
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    margin: 0 15px;
    min-width: calc(25% - 30px);
    transition: transform 0.3s, box-shadow 0.3s;
}

.testimonial-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}



.slider {
    display: flex;
    overflow-x: auto;
}

.client-profile {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.rating-stars {
    margin-top: 10px;
}

.testimonial-form-container {
    margin-top: 50px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.testimonial-form-container form {
    display: grid;
    gap: 20px;
}

.rating-input label {
    margin-right: 5px;
}

@media (max-width: 768px) {
    .testimonial-card {
        min-width: 100%; /* Full width on small screens */
    }
    
    .testimonial-form-container form {
        grid-template-columns: 1fr; /* Stack form fields on small screens */
    }
}


@keyframes bubbleAnimation {
    0% { transform: translateY(100%); }
    100% { transform: translateY(-100%); }
}

.bubble {
    position: absolute;
    bottom: 0;
    animation: bubbleAnimation 10s infinite;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
}

.testimonials {
    position: relative;
    overflow: hidden;
    /* ... your existing styles */
}

.testimonials:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    left: 20%;
    animation-delay: 2s;
    /* Apply bubble styles */
}

.testimonials:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 0;
    left: 50%;
    /* Apply bubble styles */
}

.testimonial-form-container {
    background: rgba(255, 255, 255, 0.3); /* Increased the opacity a bit for clarity */
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 238, 0, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* padding: 20px; */
    border-radius: 10px;
    /* margin: 50px 0; */
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.testimonial-form-container input,
.testimonial-form-container textarea,
.testimonial-form-container button {
    display: block;
    width: inherit;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid rgba(255, 238, 0, 0.849);
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(0, 0, 0, 0.8);
    transition: background 0.3s, transform 0.3s;
}

.testimonial-form-container input:focus,
.testimonial-form-container textarea:focus {
    background: rgba(255, 255, 255, 0.2);
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.8);
    transform: scale(1.01);
}

.testimonial-form-container button {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: none;
}

.testimonial-form-container button:hover {
    background: rgba(0, 0, 0, 0.9);
}

.testimonial-form-container .rating-input {
    display: flex;
    gap: 5px;
    align-items: center;
}

.testimonial-form-container .rating-input input[type="radio"] {
    display: none;
}

.testimonial-form-container .rating-input label {
    cursor: pointer;
    transition: color 0.3s;
}

.testimonial-form-container .rating-input input[type="radio"]:checked + label,
.testimonial-form-container .rating-input label:hover {
    color: gold;
}

.testimonial-title {
    font-size: 1.5em;
    /* margin-top: 30px; */
    /* margin-bottom: 20px; */
    /* margin: auto; */
    color: #ffffff;
    text-align: center;
    /* background: rgba(243, 247, 15, 0.2); */
}

@media (max-width: 768px) {
    .testimonial-form-container {
        padding: 10px;
        max-width: 500px;
    }
    .testimonial-title {
        font-size: 1.5em;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}

.services-section {
    padding: 50px;
    background-color: #f5f5f5; /* Adjust background color if needed */
}

.services-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2em;
}

.services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; /* For modern browsers to set gap between flex items */
}

.service-card {
    flex: 1 0 calc(25% - 20px); /* This makes sure 4 cards in a row and accounts for the gap */
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s;
}

.service-card:hover {
    transform: translateY(-10px); /* This gives a slight lift effect */
}

.service-icon i {
    font-size: 3em; /* Adjust icon size if needed */
    margin-bottom: 15px;
}

.service-title {
    margin-bottom: 10px;
}

.service-description {
    font-size: 14px;
}

/* Responsiveness */
@media (max-width: 768px) {
    .service-card {
        flex: 1 0 calc(50% - 20px); /* 2 cards in a row for smaller screens */
    }
}

.service-icon img {
    width: 60px;  /* Adjust as per your needs */
    height: 60px; /* Adjust as per your needs */
}

.gift-cards-section {
    /* border: #333 solid 2px; */
    /* padding: 10px; */
    background-color: #f4f4f4;  /* This is just a placeholder; adjust color as per your needs */
    text-align: center;
}

.gift-cards-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.gift-cards-grid {
    /* width: inherit; */
    /* border: #333 solid 1px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Space between cards */
}

.gift-card {
    width: calc(25% - 60px); /* Adjusting for 4 cards in a row and gap space */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border: 1px solid #ddd;
    transition: transform 0.3s;
    text-decoration: none;
    color: inherit;
}

.gift-card:hover {
    transform: translateY(-10px); /* Smooth transition upwards when hovered */
}

.gift-card-image img {
    max-width: 100%;
    height: auto;
    border-radius: 5px; /* Just to round corners a bit */
}

.gift-card-title {
    margin: 10px 0;
    font-size: 18px;
}

.want-gift-btn {
    padding: 5px 15px;
    border: none;
    background-color: #d89f0a; /* Adjust color as per your branding */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.want-gift-btn:hover {
    background-color: #c08000; /* Slightly darker shade for hover */
}

/* Responsiveness for smaller devices */
@media (max-width: 768px) {
    .gift-card {
        width: 100%; /* Adjusting for 2 cards in a row on smaller screens */
    }
    
    .customer-logout{
    padding: 5px;
    font-size: 13px;
    margin-left: 20px;
}
}

@media (max-width: 480px) {
    .gift-card {
        width: 100%; /* Single card in a row on very small screens */
    }
}

.packages-section {
    /* padding: 40px 5%; */
    background-color: #f5f5f5;  /* Background placeholder, adjust as per your needs */
    text-align: center;
}

.packages-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.packages-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Space between cards */
}

.package-card {
    width: calc(25% - 20px); /* Adjusting for 4 cards in a row and gap space */
    padding: 20px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);  /* This will give the glass-like transparency */
    backdrop-filter: blur(10px);  /* This will blur the background content seen through the card */
    border: 1px solid rgba(255, 255, 255, 0.18); /* This is for a subtle border highlight */
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
}

.package-card:hover {
    transform: scale(1.05); /* Slight zoom on hover */
}

.package-type {
    font-size: 20px;
    margin-bottom: 10px;
    color: #d89f0a; /* Placeholder for a gold color, adjust if necessary */
}

.package-details {
    margin-bottom: 10px;
}

.package-price {
    font-size: 24px;
    margin-bottom: 20px;
}

.purchase-btn {
    padding: 5px 20px;
    border: none;
    background-color: #d89f0a; /* Adjust as per your branding */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.purchase-btn:hover {
    background-color: #c08000; /* Slightly darker shade for hover */
}

/* Responsiveness for smaller devices */
@media (max-width: 768px) {
    .package-card {
        width: 100%; /* Adjusting for 2 cards in a row on smaller screens */
    }
}

@media (max-width: 480px) {
    .package-card {
        width: 100%; /* Single card in a row on very small screens */
    }
}

/* Product container */
.product-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
    padding: 20px;
    background-color: #fff;
}

.product-container img {
    width: 400px;
    height: 400px;
    object-fit: cover;
}

.product-description {
    flex: 1; 
    margin-left: 20px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Make the buttons more prominent */
.product-description .add-to-cart,
.want-gift-btn,
.purchase-btn {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.product-description .add-to-cart:hover,
.want-gift-btn:hover,
.purchase-btn:hover {
    background-color: darkgray;
}
.more-images {
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
}

.more-images img {
    display: inline-block;
    width: 100px; /* adjust based on what you need */
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
}

/* hide scrollbar for both Chrome and Firefox */
.more-images::-webkit-scrollbar {
    display: none;
}

.more-images {
    scrollbar-width: none;
}

.slide-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    background: rgba(0,0,0,0.5);
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
}

#slideLeft {
    left: 0;
}

#slideRight {
    right: 0;
}



/* Responsive adjustments */

/* For smaller screens */
@media only screen and (max-width: 768px) {
    .product-container {
        flex-direction: column;
    }

    .product-description {
        margin-left: 0;
        margin-top: 20px;
    }

    .product-container img {
        width: 100%;
        max-width: 300px;
        height: 300px;
    }
}

/* Footer Styles */
/* Footer.css */

.footer {
    background-color: #0d0d0d;
    color: #ffffff;
    padding: 60px 0 20px 0;
    font-family: 'Arial', sans-serif;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-section {
    flex: 1 1 250px;
    margin: 20px 0;
  }
  
  .footer-title {
    font-size: 20px;
    margin-bottom: 20px;
    position: relative;
    text-transform: uppercase;
  }
  
  .footer-title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    background-color: #e91e63;
    left: 0;
    bottom: -10px;
  }
  
  .footer-description {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .footer-socials {
    display: flex;
    gap: 15px;
  }
  
  .footer-social-link {
    color: #ffffff;
    font-size: 18px;
    transition: color 0.3s;
  }
  
  .footer-social-link:hover {
    color: #e91e63;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 10px;
  }
  
  .footer-links a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: #e91e63;
  }
  
  .footer-form {
    display: flex;
    flex-direction: column;
  }
  
  .footer-input {
    padding: 10px;
    border: none;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .footer-button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #e91e63;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .footer-button:hover {
    background-color: #d81b60;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 40px;
    font-size: 14px;
    border-top: 1px solid #333333;
    padding-top: 20px;
  }

  
.social-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .facebook-icon {
    color: #3b5998; /* Facebook color */
  }
  
  .twitter-icon {
    color: #1da1f2; /* Twitter color */
  }
  
  .instagram-icon {
    color: #c32aa3; /* Instagram color */
  }
  
  .linkedin-icon {
    color: #0077b5; /* LinkedIn color */
  }
  
  .youtube-icon {
    color: #ff0000; /* YouTube color */
  }

  .whatsapp-icon {
    color: #37ff00; /* YouTube color */
  }

  