/* src/components/UserManagement/ViewAdDetails.css */
/* src/components/UserManagement/ViewAdDetails.css */

.view-ad-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.column {
  flex: 1;
  max-width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  margin: 10px;
  border: #ebc47a5e 1px solid;
  overflow-y: auto;
  text-align: center;
  border-radius: 10px;
  max-height: 470px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.video-column{
  background: #333;
  color: #fff;
  
}




.video-container {
  width: 100%;
  max-height: 300px; /* Adjust the maximum height as needed */
  position: relative;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.images-container {
  /* width: auto; */
  /* overflow-y: auto; */
  margin: 10px;
  border: solid 1px #333;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; /* Distribute images evenly on the row */
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
  background-color: #fff;
  padding: 10px;
  /* max-height: 400px; */
}

.images-container img {
  width: calc(50.33% - 10px); /* 33.33% width for each image with margin between them */
  /* width: 100%; */
  height: auto;
  margin-bottom: 10px;
  box-sizing: border-box;
  /* border: #3333339d solid 1px; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.description-content {
  overflow-y: auto; /* Apply scroll for overflow */
  max-height: 200px; /* Adjust as needed */
  padding: 10px; /* Optional: Add padding for better aesthetics */
  border: 1px solid #ccc; /* Optional: Add border for better visibility */
  text-align: justify;
}

.comment-reply-container {
  margin-top: 20px;
  border-top: #333 solid 1px;
  text-align: justify;
  padding: 10px;
}

.comment {
  background-color: #d4a747;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #474444;
}
.comment-reply-form{
  margin-top: 10px;
  background: #333;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.comment form {
  margin-top: 10px;

}

.comment-reply-form textarea {
  width: auto;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 100px;
}

.comment-button {
  background-color: #96560d;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.comment-button:hover {
  background-color: #9e723f;
}


@media (min-width: 768px) {
  .view-ad-container {
    flex-direction: row-reverse;
  }

  .column {
    max-width: 48%;
    /* overflow-y: hidden; */
  }

  .video-container {
    max-height: none; /* Allow video to take full height on larger screens */
  }

  .images-container img {
    width: 100%;
  }

}

/* Add this CSS to your stylesheet or in a <style> tag */
.whatsapp-icon {
  /* border: solid 1px black; */
  position: fixed;
  bottom: 0px;
  right: 5px;
  z-index: 1000;
}

.whatsapp-icon a {
  display: block;
  background: transparent;
}

.whatsapp-icon img {

  margin-top: 30px;
  margin-bottom: 10px;
  width: 50px; /* Adjust the size as needed */
  height: auto;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  /* font-size: 50px; */
}

.whatsapp-icon img:hover {
  transform: scale(1.1);
}

.social-buttons-container{
  border-radius: 10px;
  /* border: #333 solid 2px; */
  background-color: #333;
  margin: 10px;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.social-buttons-container > *{
  border: none;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
}

.like-button{
  background: rgb(150, 35, 35);
  color: #fff;
}

.share-button{
  background-color: cadetblue;
  color: #fff;
}


@media (max-width: 767px) {
  /* Styles for screens smaller than 768px */
  .column {
    overflow-y: hidden;
    max-height: none; /* Reset max-height */
  }

  .description-content {
    max-height: none; /* Reset max-height */
  }
}

.images-title{
  border-top: 1px white solid;
  border-bottom: 1px white solid;
  padding: 10px;
}