/* src/assets/services.css */
.service-container{
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;

}

.service-container h1{
    /* background-color: #000000; */
    padding-right: 10px;
    color: #707c7c;
    /* border-radius: 10px; */
    text-align: right;
    border-bottom: #000000 solid 2px;
    border: solid 2px #abb8bf;
    box-shadow: 0 0 10px rgba(124,140,140, 0.955);
}
.card-container-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px;
    border: solid 2px #abb8bf;
    box-shadow: 0 0 10px rgba(124,140,140, 0.955);
  }
  
  .custom-card-services {
    width: 200px; /* Adjust the width as needed */
    flex: 1 0 auto;
    padding: 10px;
    border-radius: 10px;
  }

  .custom-card-services {
    position: relative;
    border: 1px solid #ddd; /* Border color */
  }
  
  .card-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.1); /* Transparent background */
    padding: 5px;
    border-radius: 20px 0px 10px 20px;
    border: #000000 solid 1px;
  }
  
  .card-buttons button {
    margin-right: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .card-buttons button:hover {
    color: red; /* Change color on hover */
  }
  

  .datatable-container {
    margin-top: 20px;
    /* border: solid 1px black; */
    border-radius: 10px;
    padding: 10px;
    border: solid 1px #abb8bf;
    box-shadow: 0 0 10px rgba(124,140,140, 0.955);
    /* background-color: #161d26; */
  }
  .datatable-container h2{
    /* background-color: #000000; */
    padding-right: 10px;
    color: #7c8c8c;
    /* border-radius: 10px; */
    text-align: right;
    border: solid 2px #abb8bf;
    box-shadow: 0 0 10px rgba(124,140,140, 0.955);
  }
  
  /* Style the table to cover the whole width */
  .table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: #625e5e; /* Set background color for the entire table */
    /* border-bottom: solid 2px black;
    border-left: solid 2px black;
    border-right: solid 2px black; */
  }
  
  /* Style the table headers with background color */
  .table thead {
    background-color: #7c8c8c; /* Adjust the background color as needed */
    color: #000000; /* Set text color for headers */
  }
  
  /* Style the table rows */
  .table tbody tr {
    background-color: #f8f9fa; /* Set background color for alternating rows if needed */
  }
  
  /* Style the table cells */
  .table td, .table th {
    padding: .75rem;
    text-align: center;
    color: #000000; /* Set text color for headers */
  }
  
  /* Style the action buttons in the table */
  .table td .btn {
    width: 100%;
    background-color: #7f7f8b;
    color: black;
    border: none;
  }


  /* Update the styles for the card container */
.card-container-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
}

/* Update the styles for the custom card */
.custom-card-view {
  flex-basis: calc(33.33% - 20px); /* Adjust based on 3 columns with gap */
  padding: 10px;
  border-radius: 10px;
  text-align: justify;
  border: solid 2px #abb8bf;
  box-shadow: 0 0 10px rgba(124,140,140, 0.955);
  display: flex;
  flex-direction: column; /* Ensure content doesn't overflow */
  justify-content: space-between; /* Fill the available vertical space */
}

/* Update the styles for the card image */
.card-img-top {
  object-fit: contain; /* Ensure the image is contained within the card */
  height: auto; /* Set your desired height */
  width: 100%;
  border-radius: 10px 10px 0 0; /* Rounded corners only on top */
}

  /* styles.css */

.add-cart-btn{
  background-color: #8484ad;
  border: solid 2px #abb8bf;
  box-shadow: 0 0 10px rgba(124,140,140, 0.955);
  padding: 10px;
  color: #000000;
  cursor: pointer;
}

/* Add styles for the go-back button */
.btn-go-back {
  position: fixed;
  bottom: 20px;
  right: 80px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #7f7f8b;
}

/* Add styles for the floating cart button */
.btn-floating-cart {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #7f7f8b;
}

.btn-floating-create{
    position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #7f7f8b;
}

/* Add styles for the cart modal overlay */
.cart-modal {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90vw;
  width: 80%;
  background-color: #918181;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Style the modal header */
.cart-modal .modal-header {
  background-color: #007bff;
  color: #fff;
  border-bottom: none;
}

/* Style the modal title */
.cart-modal .modal-title {
  font-size: 1.5rem;
}

/* Style the modal body */
.cart-modal .modal-body {
  padding: 20px 0;
}

/* Style the cart items in the modal body */
.cart-modal .modal-body p {
  margin: 10px 0;
  font-size: 1.2rem;
}

/* Style the modal footer */
.cart-modal .modal-footer {
  border-top: none;
  padding: 20px 0;
}

/* Style the Close and Checkout buttons in the modal footer */
.cart-modal .modal-footer button {
  font-size: 1rem;
  padding: 10px 20px;
  margin-right: 10px;
}

.service-title{
  text-align: center;
  text-decoration: underline;
}

/* Additional styles to make the modal more responsive */
@media (max-width: 768px) {
  .cart-modal {
    width: 90%;
  }

  .service-container{
    margin-top: calc(60px + 20px); /* Adjusted margin top (navbar height + additional margin) */

}
}
@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .service-table {
    width: 100%;
    border-collapse: collapse;
    display: flex;
    /* border: #000000 solid 2px; */
    padding: 10px;
    margin: auto;
  }

  .service-table > *{
    margin: 0px auto;
  }

  .service-table thead, tbody{
    border: #e19393 solid 1px;
    padding: 10px;
   
  }

  .non-disp{
    visibility: hidden;
  }

    
  .service-table tbody{
    width: 100%;
    
   }

  .service-table tbody td {
    width: 100%;
  }



  .service-table th,
  .service-table td {
    width: inherit;
    display: flex;
    flex-direction: column;
  }

  /* .service-table th {
    display: block;
  } */
/* 
  .service-table td {
    display: block;
    border-top: 1px solid #dee2e6;
  } */

  /* .service-table tbody td:before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }

  .service-table tbody tr {
    border-top: 2px solid #dee2e6;
    margin-top: 20px;
  } */
}

.service-card-btns{
  display: flex;
  gap: 10px;
}
.service-card-btns > *{
  width: 100px;
  background-color: #6c4949;
  padding: 10px;
  color: #ddd;
  border-radius: 10px;
  cursor: pointer;

}

.users-bookings{
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.users-bookings button{
  background-color: #6c4949;
  padding: 10px;
  color: #ddd;
  border-radius: 10px;
  cursor: pointer;
}

.cart-app-btns{
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.note-title{
  color: rgb(210, 27, 27) !important;
}


@media (max-width: 768px) {
  
.cart-app-btns{
flex-direction: column;
width: inherit;
}

.cart-app-btns > *{
  width: 100%;
}
  .service-container {
    padding: 0 15px;
  }

  .card-container-view {
    justify-content: center;
  }

  .card-container-view .col {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  .card-img-top {
    max-width: 100%;
    height: auto;
  }

  .btn-go-back{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }

  /* .btn-floating-create {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  } */
  .btn-floating-create {
    position: fixed;
    bottom: 20px; /* Adjust this value to your preference */
    left: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    background-color: #7f7f8b;
  }
  

}


.service-action-btns{
  display: flex;
  justify-content: space-around;
  gap: 10px;
}


.accounts-nav-btns{
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 20px;
  margin-top: 10px;
  background-color: #909f80;
  color: #ffffff;
}

.accounts-nav-btns:hover{
  background-color: white;
  border: #ba9773 solid 2px;
  color: #ba9773;
}
