.schedule-form-banner {
    /* Add your styles here */
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the child elements horizontally */
  }
  
  .schedule-form-banner-child {
    /* Add your styles here */
    margin: 20px; /* Adjust margin as needed */
  }
  
  .time-inputs {
    /* Add your styles here */
    display: flex;
    justify-content: space-around;
    align-items: center; /* Center the items vertically */
  }
  
  .time-inputs select,
  .time-inputs input {
    /* Add your styles here */
    padding: 10px;
    height: 40px; /* Adjust height as needed */
  }
  
  .room-images-container {
    /* Add your styles here */
    display: flex;
    overflow-x: auto;
    height: 200px; /* Adjust height as needed */
    margin-top: 20px; /* Add margin-top */
  }
  
  .room-container {
    /* Add your styles here */
    margin-right: 10px;
  }
  
  .room-image {
    /* Add your styles here */
    width: 140px;
    height: auto;
  }
  
  .therapist-details {
    /* Add your styles here */
    margin-top: 20px;
  }
  
  .profile-picture {
    /* Add your styles here */
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .profile-picture img {
    /* Add your styles here */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Add these styles to your existing CSS file or create a new one */

/* Styles for the container of the TimePicker */
.react-time-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px; /* Adjust width as needed */
    margin-bottom: 10px;
  }
  
  /* Styles for the input field */
  .react-time-picker__inputGroup {
    display: flex;
    flex-direction: column;
    width: 100px; /* Adjust width as needed */
  }
  
  .react-time-picker__inputGroup__input {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Styles for the dropdown arrow */
  .react-time-picker__inputGroup__dropdownIcon {
    font-size: 20px;
    color: #555;
    cursor: pointer;
  }
  
  /* Styles for the dropdown container */
  .react-time-picker__dropdown {
    z-index: 1000; /* Ensure it appears above other elements */
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
  }
  
  /* Styles for individual dropdown items */
  .react-time-picker__dropdown__item {
    padding: 4px 8px;
    cursor: pointer;
  }
  
  /* Styles for the selected item in the dropdown */
  .react-time-picker__dropdown__item--selected {
    background-color: #e0e0e0;
  }
  
  /* Styles for the dropdown scrollbar (if needed) */
  .react-time-picker__dropdown::-webkit-scrollbar {
    width: 6px;
  }
  
  .react-time-picker__dropdown::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
  }
  
  .time-inputs .time-labels{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
  
  @media (max-width: 768px) {
    .time-inputs {

      align-items: center; /* Center the items horizontally */
    }

  }
  