/* AddRole.css */

.add-role-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: solid 2px #abb8bf;
    box-shadow: 0 0 10px rgba(124,140,140, 0.955);
    border-radius: 8px;
  }
  
  h2 {
    text-align: center;
    color: #abb8bf;
    
    border-bottom: 2px solid #333;
  }
  
  .add-role-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .role-back-button {
    padding: 10px;
    background-color: #abb8bf;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    display: inline-block;
  }

  .back-button i {
    margin-right: 5px;
  }

 .add-role-form label {
    font-weight: bold;
  }

  .add-role-form button {
    font-weight: bold;
    padding: 10px;
    background-color: #abb8bf;
    color: #ddd;
    border-radius: 10px;
    cursor: pointer;
  }

  .add-role-form button:hover {
    font-weight: bold;
    padding: 10px;
    background-color: #000000;
    color: #ddd;
  }
  
  
  
  .add-role-form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }

  /* .add-role-form textarea{
    height: 800px;
  }
   */
  h3 {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
    font-family: 'Rokkitt', sans-serif;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .checkbox-cell {
    text-align: center;
  }
  
  .add-role-btn {
    padding: 10px;
    background-color: #503e12;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .add-role-container {
      max-width: 100%;
    }

    .back-button {
      width: 100%;
      margin-right: 0;
    }

    .back-button i {
      display: none;
    }
  }
