/* VideoModal.css */

.video-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    margin: 0px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-modal-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .video-modal-content {
    display: flex;
    gap: 20px;
  }
  
  .video-section {
    width: 70%;
  }
  
  .user-profile-section {
    width: 30%;
  }
  
  .video-modal-description {
    margin-top: 20px;
  }
  
  .video-modal-footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .like-dislike-section {
    display: flex;
    gap: 10px;
  }
  
  .like-button, .dislike-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .like-button:hover, .dislike-button:hover {
    background-color: #45a049;
  }
  .video-modal {
    /* ... (existing styles) ... */
    margin-top: 70px; /* Adjust as needed based on your top navigation bar height */
  }
  
  .user-profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .user-details {
    text-align: center;
  }
  
  .explore-buttons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }  