.footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    flex-wrap: wrap;
  }
  
  .footer-logo h2 {
    margin-bottom: 10px;
  }
  
  .footer-links ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-links ul li {
    margin-bottom: 10px;
  }
  
  .footer-links ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-links ul li a:hover {
    color: #ffa500; /* Change color on hover */
  }
  
  .footer-social .social-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-icon {
    font-size: 24px;
    color: #fff;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #ffa500; /* Change color on hover */
  }
  
  .footer-bottom {
    text-align: center;
    padding: 10px 20px;
    border-top: 1px solid #444;
    margin-top: 20px;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 14px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-logo, .footer-links, .footer-social {
      margin-bottom: 20px;
    }
  
    .footer-links ul {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .footer-links ul li {
      margin-bottom: 8px;
    }
  
    .footer-social .social-icons {
      justify-content: center;
      gap: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .footer-logo h2 {
      font-size: 24px;
    }
  
    .social-icon {
      font-size: 20px;
    }
  
    .footer-bottom p {
      font-size: 12px;
    }
  }
  