/* styles.css */

/* Set basic styles for the body */
body {
  margin: 0;
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Kalnia", serif;
  font-optical-sizing: auto;
 
}

:root {
    --sidebar-width: 20%; /* Set the sidebar width as a CSS variable */
    --top-nav-height: 60px; /* Set the top navigation height as a CSS variable */
  }
  
  .top-navigation {
    /* background-color: rgba(255, 255, 255, 0.507); */
    
     background-color: #e9e9e9 !important;
     /* background: url('assets/images/nature.jpg') center/cover fixed; */
     /* background: url('../images/nature.jpg') center/cover fixed; */
     /* background: rgba(213, 183, 104, 0.2); */
     /* box-shadow: 0 2px 5px rgba(228, 195, 6, 0.479); */
    /* background-color: rgba(255, 255, 255, 0.685); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    box-shadow: 0 2px 5px rgba(78, 78, 73, 0.767);
    position: fixed; /* Fixed position for the top navigation */
    width: calc(100% - var(--sidebar-width)); /* Calculate the width of the top navigation */
    margin-left: var(--sidebar-width); /* Adjust margin to accommodate the fixed sidebar */
    z-index: 100; /* Set a high z-index to keep it above other elements */
  }
  
  /* Style for the main content area */
  .main-content {
    /* background: url('assets/images/nature.jpg') center/cover fixed; */
    /* background: linear-gradient(to left, rgba(0,0,0,0.4)50%, rgba(0,0,0,0.4)50%),url('assets/images/nature.jpg') center/cover fixed; */
    padding: 20px;
    overflow-y: auto; /* Allow main content to be scrollable */
    margin-left: var(--sidebar-width); /* Adjust margin to accommodate the fixed sidebar */
    margin-top: var(--top-nav-height); /* Adjust margin to accommodate the top navigation height */
    width: calc(100% - var(--sidebar-width)); /* Calculate the width of the main content area */
  }
/* Style for the navbar content */
#basic-navbar-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px 0px;
}

/* Style for the dashboard logo */
.dash-logo {
  display: none;
  width: 70%;
  border-radius: 150px;
  box-shadow: 0 0 40px rgba(20, 20, 20, 0.9);
  margin-left: 10px;
}

.menu-toggle-icon{
  /* visibility: hidden; */
    display: none;
    width: 200px;
    border: none;
    height: auto;
    /* border: solid 1px rgb(213, 183, 104); */
    padding: 10px;
    font-size: 17px;
    /* background-color: rgb(255, 255, 255); */
    background-color: #5f666d;
    /* border-radius: 10px; */
    text-align: center;
    /* font-family: 'Cherry Bomb One', cursive; */
    font-family: "Kalnia", serif;
    font-optical-sizing: auto;  
    cursor: pointer;
    color: rgba(255, 255, 255, 0.781);
}

.create-advert-button{
    width: 100px;
    /* height: auto; */
    border: solid 1px rgb(0, 0, 0);
    padding: 6px;
    font-size: 14px;
    background-color: rgb(0, 0, 0);
    border-radius: 50px;
    text-align: center;
    /* font-family: 'Cherry Bomb One', cursive; */
    font-family: "Kalnia", serif;
    font-optical-sizing: auto;  
    cursor: pointer;
    color: #ffffff !important;
    text-decoration: none;
}

/* Center the items vertically in the navbar */
#basic-navbar-nav > * {
  margin: auto 0px;
}

/* Style for the search form */
#basic-navbar-nav .header-search-form {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  border: rgb(179, 0, 0) solid 2px;
}

/* Style for the search form elements */
#basic-navbar-nav .header-search-form > * {
  padding: 6px;
  width: 300px;
}

/* Style for the search button */
#basic-navbar-nav .header-search-form button {
  width: 50px;
  height: 50px;
  padding: 0;
}

/* Adjust the width and padding for the search button */
.search-btn {
  width: 20px;
  padding: 0;
}

/* Style for the brand and icons section */
.brand-and-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  width: 200px;
}

/* Set color for brand and icons */
.brand-and-icons > * {
  color: rgba(103, 102, 102, 0.781);
}

/* Hide menu toggle and search icons by default */
.search-icon, .menu-toggle-icon-small {
  display: none;
}

/* Additional styling for the search form */
.form-inline {
  display: flex;
  align-items: center;
}

/* Style the search input */
.form-inline .form-control {
  margin-right: 10px;
}

/* Style the search button */
.form-inline .btn {
  background-color: #fff;
  color: #000;
}

.wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden; /* Hide overflow for the entire wrapper */
}

/* Style for the sidebar */
.sidebar {
    /* font-family: 'Cherry Bomb One', cursive; */
    font-family: "Kalnia", serif;
    font-optical-sizing: auto;
   
  /* background-color: #000; */
  /* background: url('../images/nature.jpg') center/cover fixed; */
  /* background-color: rgba(255, 222, 104, 0.507); */
  /* background-color: rgba(255, 255, 255, 0.685); */
  /* background-color: #d0dce1; */
  background-color: #ffffff;
  width: var(--sidebar-width); /* Use the CSS variable for the sidebar width */
  position: fixed; /* Fixed position for the sidebar */
  height: 100%; /* 100% height for the sidebar */
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(124,140,140, 0.479);
}


/* Style for the sidebar logo */
.sidebar-logo-watch {
  width: 90%;
    /* min-width: 50%;
    max-width: 50%; */
    height: auto;
    /* border-radius: 30px; */
    box-shadow: 0 0 6px #ba9773;
    /* border: solid 1px #545c64; */
    margin: 30px auto;
  }

  .flex-column{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: #ffffff solid 2px;
    border-radius: 10px;
    margin: 7px;
    padding: 10px;
    overflow-y: auto; /* Allow sidebar content to be scrollable */
  }

  /* Style for the sidebar links */
.sidebar-link {
    /* color: rgba(0, 0, 0, 0.781); */
    color: #7c5e3f;
    padding: 10px; /* Set padding for better spacing */
    text-decoration: none; /* Remove underline from links */
    display: flex;
    gap: 6px;
    align-items: baseline;
    border-bottom: 1px #909f80 solid;
    /* background-color: #333; Change background color on hover */
    /* background: rgba(213, 183, 104, 0.2); */
    border-radius: 10px;
  }
  
  .sidebar-link:hover {
    background-color: rgba(86,80,95, 0.822); /* Change background color on hover */
    color: #fff;
    border: none;
  }
  
 

/* Media query for smaller devices */
@media (max-width: 768px) {

    .top-navigation {
        /* background-color: #ffffff; */
        background-color: #545c64;
        margin-left: 0px;
        width: calc(78% + var(--sidebar-width));
    }
    /* Adjust the flex direction and alignment for smaller screens */
    #basic-navbar-nav {
      flex-direction: row;
      align-items: flex-start;
    }
  
    /* Display menu toggle and search icons for smaller screens */
    .dash-logo,
    .search-icon, .menu-toggle-icon-small {
      display: block;
    }
  
    /* Hide the search form for smaller screens */
    #basic-navbar-nav .header-search-form, .menu-toggle-icon{
      display: none;
    }
    .basic-navbar-nav{
        border: solid rgb(255, 255, 255) 1px;
        margin: 10px;
    }
  
    /* Adjust padding for the brand and icons section */
    .top-navigation .brand-and-icons {
      /* padding: 6px; */
      /* margin-right: 100px; */
      /* border: solid white 1px; */
    }

    .top-header-search-btn{
      font-size: 18px;
    }
    .brand-and-icons > * {
      color: #545c64;
      
        /* box-shadow: 0 0 10px rgba(213,183,104, 0.5); */
      }
    .sidebar, .sidebar-logo-watch {
      display: none;
    }
  
    .main-content {
   
      margin-left: 0px; /* Adjust margin to accommodate the fixed sidebar */
      width:100%; /* Calculate the width of the main content area */
    }
  }

  .sidebar-user-profile-container{
    width: auto;
    /* border: solid black 1px; */
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #909f80;
    color: #fff;
    font-family: 'Courier New', Courier, monospace;
    display: flex;
    flex-direction: column;
  }

  .sidebar-user-profile{
    width: auto;
    /* border: solid black 1px; */
    /* padding: 10px; */
    display: flex;
    /* margin: auto 0px; */
    gap: 10px;
  }
  .sidebar-user-profile > *{
    margin: auto 0px;
    font-size: 14px;
  }

  .sidebar-profile-picture{
    /* border: solid black 1px; */
    border-radius: 50px;
    width: 50px;
    height: 50px;
  }
  
/* Add this style to your CSS file */
.sidebar-link.active {
  background-color: #909f80; /* Change background color for active link */
  color: #fff;
  border: none;
}

/* Sidebar.css */
.sidebar-logo-watch {
  /* Initial styles for the logo */
  transition: transform 0.5s ease-in-out;
}

.sidebar-logo-watch.flipped {
  /* Flipping animation */
  transform: rotateY(180deg);
}

/* Sidebar.css */
.dash-logo {
  /* Initial styles for the logo */
  transition: transform 0.5s ease-in-out;
}

.dash-logo.flipped {
  /* Flipping animation */
  transform: rotateX(180deg);
}

/* Add infinite loop animation for the ad */
.sidebar-ad-container {
  animation: rotateAd 10s linear infinite;
}

@keyframes rotateAd {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.responsive-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Ensure the overlay is above other elements */
}



@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dash-logo.spin {
  animation: spin 1s linear infinite;
}
