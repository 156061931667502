/* createAdStyles.css */

/* Container for form */
.create-ad-container {
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
}

/* Flex container for horizontal layout */
.create-ad-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Style for form labels */
.create-ad-label {
  margin-bottom: 10px;
  font-weight: bold;
}

/* Style for form inputs and select */
.create-ad-input,
.create-ad-select {
  margin-bottom: 10px;
  padding: 8px;
  box-sizing: border-box;
  width: 100%; /* Full width for all inputs and selects */
}

/* Style for submit button */
.create-ad-submit {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.role-back-button{
  text-decoration: none;
}

/* Separate styles for columns in form sections */
.form-section {
  display: flex;
  justify-content: space-between;
}

.create-add-form-column {
  width: 48%; /* Adjust as needed */
}

.create-add-form-column-full-width {
  width: 100%; /* Adjust as needed */
}
