/* MobileSidebar.css */
/* MobileSidebar.css */
.mobile-sidebar {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 300px;
  max-height: 500px; /* Adjust the max height as needed */
  background-color: rgba(223, 219, 217, 0.849);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* border: solid black 2px; */
  box-shadow: 0 2px 10px rgba(228, 195, 6, 0.712);
  margin: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
}



  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #000;
  }
  
  .search-form {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 40px;
    border: #000 solid 1px;
    display: flex;
    padding: 5px;
  }
  
  .search-input {
    width: inherit;
    padding: 10px;
    margin-right: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .search-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #000;
  }
  
  .mobile-sidebar a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
    /* margin-bottom: 20px; */
  }
