/* UserProfileSidebar.css */

/* Style for the user profile sidebar */
.user-profile-sidebar {
  position: fixed;
  top: var(--top-nav-height);
  right: 0;
  height: calc(100vh - var(--top-nav-height));
  width: 250px;
  background-color: #333;
  color: #fff;
  box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-family: 'Rokkitt', sans-serif;
  z-index: 1000; /* Set a high z-index to ensure it appears on top of other elements */
}

/* Style for the user profile header */
.user-profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #444;
  padding-top: 30px;
}

/* Style for the close button */
.close-button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  width: 50px;
  height: auto;
  
}

/* Style for the user profile content */
.user-profile-content {
  padding: 20px;
}

/* Style for the logout button */
.logout-button {
  background-color: #d9534f;
  color: #fff;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  
}

/* Adjust the icon in the logout button */
.logout-button svg {
  margin-right: 10px;
}

/* Style for the profile button */
.profile-button {
  background-color: #5bc0de;
  color: #fff;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  
}

/* Adjust the span in the profile button */
.profile-button span {
  margin-right: 10px;
}
