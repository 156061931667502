/* Common styles for both slider and advertisements */
/* .dashboard-content {
  padding: 20px;
} */

/* Banner styles */
.banner {
  width: inherit;
  background-position: center;
  background-size: 100% 100%; /* Cover entire height and width */
  background-image: url(../assets/images/adverts/counterback.jpg);
  background-repeat: no-repeat;
  height: 600px; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 0.9; /* Adjust opacity as needed */
  border-radius: 10px;
}




.banner .header-search-form {
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0.4; /* Adjust opacity as needed */
  width: 80%; /* Adjust width for smaller screens */
  padding: 10px;
  border: solid 2px black;
}

.banner .header-search-form input {
  padding: 15px;
  width: 100%; /* Adjust the width as needed */
  border-radius: 20px;
}

.banner-search-btn {
  width: 50px; /* Adjust width for smaller screens */
  height: 50px; /* Adjust height for smaller screens */
  /* padding: 5px; */
  background-color: #afa04c; /* Background color for the search button */
  border: none;
  cursor: pointer;
  font-size: large;
  text-align: center;
  border-radius: 10px;
  color: white;
  font-size: 24px;
}


.dashboard-content h2{
  text-align: center;
  text-transform: uppercase;
  border: solid 2px #abb8bf;
  box-shadow: 0 0 10px rgba(124,140,140, 0.955);
}

/* Common styles for advertisements */
.advertisements-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: start; /* Center the cards */
  /* border: #0b7dda solid 2px; */
}

.advertisement-card {
  width: calc(50% - 10px); /* 50% for 2 items per row on smaller screens */
  /* max-width: 310px; */
  /* border: 1px solid #000000; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.845); /* Add a subtle shadow for depth */
  padding: 1px;
  box-sizing: border-box;
  margin-bottom: 20px;
  /* text-align: center; */
  overflow: hidden;
  height: auto;
  min-height: 420px;
  position: relative;
}

.advert-image-holder{
  /* border: solid black 2px; */
  height: aut;
  /* padding: 20px; */
}

.advertisement-image {
  width: 100%;
  height: 100%;
  object-fit: 100%;
}

.advert-card-content {
  /* overflow-y: scroll; */
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  height: auto;
  /* padding-bottom: 40px; */
  /* text-align: center; */
  font-size: 16px;
  /* top: calc(100% -  220px); */
  padding: 2px;
  /* max-height: 300px; */
  /* margin: 10px 0px; */
  /* line-height: 17px; */
}

.non-title-content{
  /* border: solid 2px black; */
  display: flex;
  flex-wrap: wrap;
  width: auto;
  gap: 10px;
  justify-content: space-evenly;
  margin-top: 20px;
  /* padding: 5px; */
}

.star-rating{
  border: solid 1px gold;
  border-radius: 2px;
  display: flex;
  background: #e9900b;
  color: white;
  justify-content: space-between;
}

.star-rating > *{
  margin: auto 0px;
}

.glowing-badge{
  color: red;
  /* border: solid blue 1px; */
}

.non-title-content > *{
  margin: auto;
  
}

.button-icons {
  /* border: solid 1px black; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between; /* Adjusted to evenly distribute icons */
}

.public-content-title{
  text-align: center;
  /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
  font-family: "Kalnia", serif;
  font-optical-sizing: auto;
 
  /* border: #0b7dda 1px solid; */
}

.ad-type{
  /* border: solid 1px gold; */
  padding: 4px;
  background-color: #333;
  color: #ccc;
  /* border-radius: 10px; */
  margin-right: 0px;
}

.likes-dislikes{
color:#e9900b;
}
.play-button,
.view-button,
.likes-button,
.read-more-button {
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  flex-grow: 1; /* Each button should take equal space */
}

.play-button:hover,
.view-button:hover,
.likes-button:hover,
.read-more-button:hover {
  background-color: #e99999;
}






/* .play-button{
  width: inherit;
  background-color: rgba(209, 52, 52, 0.808);
  margin: auto;
} */
.view-button{
  background-color: #0c2030c2;
  margin: auto;
  width: 100%;
  text-align: center;
  text-decoration: none;
}
.play-button,
.view-button,
.likes-button,
.read-more-button {
  /* background-color: #af9d4c; */
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 25%;
  box-sizing: border-box;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.play-button:hover,
.view-button:hover,
.likes-button:hover,
.read-more-button:hover {
  background-color: #cf9d32;
}

/* Pagination styles */
.pagination-container {
  display: flex;
  justify-content: flex-end; /* Right-align the pagination buttons */
  margin-top: 20px;
}

.pagination-container button {
  margin: 0 5px;
  background-color: #be950ba8; /* Background color for pagination buttons */
  color: white;
  padding: 10px 12px;
  border: none;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
}

.pagination-container button:hover {
  background-color: #0b7dda; /* Darker background color on hover */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .banner {
    padding: 10px;
    height: 300px;
    border-radius: 10px;
  }

  .banner .header-search-form {
    width: 100%; /* Adjust width for smaller screens */
  }

  .advertisements-container {
    justify-content: center;
  }

  .advertisement-card {
    width: 100%; /* 100% for 1 item per row on smaller screens */
  }

  .banner-search-btn {
    display: none;
  }

  .pagination-container {
    justify-content: center; /* Right-align the pagination buttons */
  }
}
/* Thumbs styles */
.thumbs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 20px; */
  background-color: #fdb7358e;
  /* color: white; */
}

.thumbs-up-button,
.thumbs-down-button {
  background-color: #fdb735cc;
  /* color: white; */
  /* padding: 10px; */
  border: none;
  cursor: pointer;
  margin: 0 10px;
  /* border-radius: 5px; */
  font-size: 18px;
}

.thumbs-count {
  font-size: 12px;
  margin: 0 10px;
  color: #ffffff;
}

/* Add these styles to your existing CSS */

/* Slick slider image styles */
.slick-slider img {
  width: 100%; /* Ensure images fill the slider container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Add border-radius for rounded corners */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); Add a subtle shadow for depth */
}

/* Additional styles for slick slider navigation arrows */
.slick-prev,
.slick-next {
  font-size: 24px; /* Adjust arrow size */
  color: #333; /* Arrow color */
}

.slick-prev:hover,
.slick-next:hover {
  color: #555; /* Hover color */
}

/* Optional: Center the slick slider dots */
.slick-dots {
  text-align: center;
}

/* Optional: Style slick slider dots */
.slick-dots li {
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

.slick-dots li button {
  font-size: 12px; /* Adjust dot size */
  color: #333; /* Dot color */
  background-color: transparent; /* Background color */
  border: 1px solid #333; /* Border color */
  border-radius: 50%; /* Make dots circular */
  width: 12px; /* Dot width */
  height: 12px; /* Dot height */
}

.slick-dots li.slick-active button {
  background-color: #333; /* Active dot background color */
  color: #fff; /* Active dot color */
}

/* Responsive styles */
@media (max-width: 768px) {
  /* Adjust slick slider image styles for smaller screens */
  .slick-slider img {
    border-radius: 5px; /* Reduce border-radius for smaller corners */
  }

  /* Adjust slick slider navigation arrows for smaller screens */
  .slick-prev,
  .slick-next {
    font-size: 20px; /* Reduce arrow size */
  }

  /* Adjust slick slider dots for smaller screens */
  .slick-dots li button {
    font-size: 10px; /* Reduce dot size */
    width: 10px; /* Reduce dot width */
    height: 10px; /* Reduce dot height */
  }
}
