/* src/assets/css/RolePermissions.css */

/* Table Styles */
.table-container{
  /* border: #503e12 2px solid; */
  /* width: 100%; */
  /* background: url('../images/nature.jpg') center/cover fixed; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 7px;
}
.table-container h2{
    
    color: #7f7f8b;
    border-bottom: solid 2px #998042;

}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: solid 2px #d5b768;
    box-shadow: 0 0 10px rgba(213,183,104, 0.9);
    border-radius: 10px;
    color: #503e12; /* Set text color to white for better visibility */
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .custom-table th {
    background-color: #f2f2f2;
  }

  
.cart-custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: solid 2px #d5b768;
  box-shadow: 0 0 10px rgba(213,183,104, 0.9);
  border-radius: 10px;
  color: #503e12; /* Set text color to white for better visibility */
}

.cart-custom-table th,
.cart-custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.cart-custom-table th {
  background-color: #f2f2f2;
}


  
.appointment-custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: solid 2px #d5b768;
  box-shadow: 0 0 10px rgba(213,183,104, 0.9);
  border-radius: 10px;
  color: #503e12; /* Set text color to white for better visibility */
}

.appointment-custom-table th,
.appointment-custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.appointment-custom-table th {
  background-color: #f2f2f2;
}



  .service-custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: solid 2px #abb8bf;
    box-shadow: 0 0 10px rgba(124,140,140, 0.955);
    border-radius: 10px;
    color: #503e12; /* Set text color to white for better visibility */
  }
  
  .service-custom-table th,
  .service-custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .service-custom-table th {
    background-color: #f2f2f2;
  }
  
  /* Action Button Wrapper Styles */
  .action-btn-wrapper {
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    gap: 10px;
  }
  
  /* Action Button Styles */
  .action-button {
    /* margin-right: 10px; */
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: auto;
    padding: 5px;

    color: #130404;
    /* border-radius: 10px; */
    text-align: center;
    border: solid 2px #abb8bf;
    box-shadow: 0 0 5px rgba(124,140,140, 0.955);
  }
  
  /* .action-button i {
    margin-right: 5px;
  } */
  
  /* Pagination Styles */
  .pagination {
    /* margin-top: 20px; */
    display: flex;
    justify-content: flex-end;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 50px;
    background-color: #abb8bf;
    color: #ddd;
    
  }
  
  /* Search Form Styles */
  .role-search-form {
    /* border: #503e12 2px solid; */
    /* max-width: 200px; */
    /* margin-top: 10px; */
    display: flex;
    gap: 5px;
    justify-content: space-around;
  }
  
  .role-search-form input {
    padding: 10px;
    width: 100%;
    border-radius: 20px;
  }

  .role-search-form button{
    width: 100px;
  }
  
  /* Records Per Page Styles */
  .records-per-page {
    margin-top: 10px;
    /* border: #503e12 solid 2px; */
    width: 50%;
    
  }
  
  .records-per-page label {
    margin-right: 5px;
  }

  .records-per-page select{
    width: inherit;
    background-color: #abb8bf;
    color: #ddd;
    padding: 6px;
    border-radius: 20px;
    text-align: center;
    
  }
  
  /* Buttons Styles */
  .role-buttons {
    /* border: #503e12 solid 2px; */
    /* margin-top: 10px; */
    display: flex;
    width: inherit;
    /* gap: 20px; */
    /* padding: 10px; */
    
    justify-content: space-between;
  }

  .category-btn{
    float: left;
    max-width: 200px;
    padding: 10px;
    color: #819ead;
    /* border-radius: 10px; */
    text-align: center;
    border: solid 2px #abb8bf;
    box-shadow: 0 0 10px rgba(124,140,140, 0.955);
    cursor: pointer;
  }
  
  
  .add-button,
  .back-button,
  .search-button {
    
    cursor: pointer;
    background-color: #abb8bf;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    /* margin-right: 10px; */
  }

  /* RolePermissions.css or your custom stylesheet */

.ad-image {
  max-width: 100px; /* Set your preferred max-width */
  max-height: 100px; /* Set your preferred max-height */
  /* Add more styling as needed */
}

/* RolePermissions.css or your custom stylesheet */

.video-thumbnail {
  max-width: 100px; /* Set your preferred max-width for the video thumbnail */
  max-height: 100px; /* Set your preferred max-height for the video thumbnail */
  /* Add more styling as needed */
}


.video-container {
  position: relative;
  width: 200px; /* Set your preferred width */
  height: 50px;
  padding-bottom: 56.25%; /* Set your preferred aspect ratio (16:9) */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

  
  /* .add-button i,
  .back-button i,
  .search-button i {
    margin-right: 5px;
  } */
  /* src/assets/css/RolePermissions.css */

/* ... (other styles) */

/* Media Query for smaller devices like mobile phones */
@media (max-width: 767px) {
   /* Role Buttons Styles for Smaller Screens */
   .role-buttons {
    flex-direction: row; /* Change flex direction to column for smaller screens */
    align-items: center; /* Center align the buttons */
    /* border: #503e12 solid 1px; */
    width: auto;
    padding: 10px;
  }

  .add-button,
  .back-button {
    margin: auto;
  }


  .custom-table,
  .custom-table thead,
  .custom-table tbody,
  .custom-table th,
  .custom-table td,
  .custom-table tr,
  .custom-table td,
  .custom-table th,
  .custom-table tr {
    display: block;
  }
  .custom-table tbody{
    padding: 10px;
  }

  .custom-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .custom-table tbody tr {
    margin-bottom: 15px;
    border: solid 1px black;
  }

  .custom-table td {
    border: none;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
  }

  .custom-table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .custom-table td:nth-of-type(1):before {
    content: 'Record No.';
  }

  .custom-table td:nth-of-type(2):before {
    content: 'Title';
  }

  .custom-table td:nth-of-type(3):before {
    content: 'Type';
  }

  .custom-table td:nth-of-type(4):before {
    content: 'Category';
  }

  .custom-table td:nth-of-type(5):before {
    content: 'Cluster';
  }

  .custom-table td:nth-of-type(6):before {
    content: 'Status';
  }

  .custom-table td:nth-of-type(7):before {
    content: 'Action';
  }

  .custom-table th,
  .custom-table td {
    text-align: left !important;
  }


  .service-custom-table,
  .service-custom-table thead,
  .service-custom-table tbody,
  .service-custom-table th,
  .service-custom-table td,
  .service-custom-table tr,
  .service-custom-table td,
  .service-custom-table th,
  .service-custom-table tr {
    display: block;
  }
  .service-custom-table tbody{
    padding: 10px;
  }

  .service-custom-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .service-custom-table tbody tr {
    margin-bottom: 15px;
    border: solid 1px black;
  }

  .service-custom-table td {
    border: none;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
  }

  .service-custom-table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .service-custom-table td:nth-of-type(1):before {
    content: 'Record No.';
  }

  .service-custom-table td:nth-of-type(2):before {
    content: 'Category';
  }

  /* .service-custom-table td:nth-of-type(3):before {
    content: 'Description';
  } */

  .service-custom-table td:nth-of-type(3):before {
    content: 'Services';
  }

  .service-custom-table td:nth-of-type(4):before {
    content: 'Actions';
  }

  .category-description {
    text-align: justify;
  }
  
/* 
  .service-custom-table td:nth-of-type(6):before {
    content: 'Status';
  }

  .service-custom-table td:nth-of-type(7):before {
    content: 'Action';
  } */

  .service-custom-table th,
  .service-custom-table td {
    text-align: left !important;
  }



  .appointment-custom-table,
  .appointment-custom-table thead,
  .appointment-custom-table tbody,
  .appointment-custom-table th,
  .appointment-custom-table td,
  .appointment-custom-table tr,
  .appointment-custom-table td,
  .appointment-custom-table th,
  .appointment-custom-table tr {
    display: block;
  }
  .appointment-custom-table tbody{
    padding: 10px;
  }

  .appointment-custom-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .appointment-custom-table tbody tr {
    margin-bottom: 15px;
    border: solid 1px black;
  }

  .appointment-custom-table td {
    border: none;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
  }

  .appointment-custom-table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .appointment-custom-table td:nth-of-type(1):before {
    content: 'Record No.';
  }

  .appointment-custom-table td:nth-of-type(2):before {
    content: 'Title';
  }

  .appointment-custom-table td:nth-of-type(3):before {
    content: 'Date';
  }

  .appointment-custom-table td:nth-of-type(4):before {
    content: 'Time';
  }

  .appointment-custom-table td:nth-of-type(5):before {
    content: 'Duration';
  }

  .custom-table td:nth-of-type(6):before {
    content: 'Status';
  }

  .appointment-custom-table td:nth-of-type(7):before {
    content: 'Room';
  }

  .appointment-custom-table td:nth-of-type(8):before {
    content: 'Therapist';
  }

  .appointment-custom-table th,
  .appointment-custom-table td {
    text-align: left !important;
  }



  

  .cart-custom-table,
  .cart-custom-table thead,
  .cart-custom-table tbody,
  .cart-custom-table th,
  .cart-custom-table td,
  .cart-custom-table tr,
  .cart-custom-table td,
  .cart-custom-table th,
  .cart-custom-table tr {
    display: block;
  }
  .cart-custom-table tbody{
    padding: 10px;
  }

  .cart-custom-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .cart-custom-table tbody tr {
    margin-bottom: 15px;
    border: solid 1px black;
  }

  .cart-custom-table td {
    border: none;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
  }

  .cart-custom-table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .cart-custom-table td:nth-of-type(1):before {
    content: 'Record No.';
  }

  .cart-custom-table td:nth-of-type(2):before {
    content: 'Category';
  }

  /* .service-custom-table td:nth-of-type(3):before {
    content: 'Description';
  } */

  .cart-custom-table td:nth-of-type(3):before {
    content: 'Services';
  }

  .cart-custom-table td:nth-of-type(4):before {
    content: 'Duration';
  }

  .cart-custom-table td:nth-of-type(5):before {
    content: 'Price';
  }


  

  .cart-custom-table td:nth-of-type(6):before {
    content: 'Status';
  }

  .cart-custom-table td:nth-of-type(7):before {
    content: 'Schedule';
  }

  .cart-custom-table th,
  .cart-custom-table td {
    text-align: left !important;
  }
  
}

/* ... (other styles) */

/* Modal */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1000; /* Ensure modal appears on top of other content */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 80%; /* Limit modal width */
}

/* Close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Room images */
.room-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.room-image {
  width: calc(33.33% - 10px); /* Max 3 columns with 10px gap between images */
  margin-bottom: 10px;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .room-image {
    width: calc(50% - 10px); /* On smaller devices, display max 2 columns */
  }
}

@media screen and (max-width: 480px) {
  .room-image {
    width: 100%; /* On even smaller devices, display as single column */
  }
}

