.banner-container {
  background-image: url(/src/assets/images/home-banner.png);
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 20px;
}

.banner-content {
  max-width: 800px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.2); /* Adding a translucent white background */
  padding: 20px; /* Adding some padding */
  border-radius: 10px; /* Softening the corners */
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.133); /* Adding a fluorescent glow */
  background: linear-gradient(rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.13)), url(/src/assets/images/home-banner.png); /* Adding a gradient overlay */
  background-size: cover;
  background-position: center;
}

.banner-title {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #ffffff; /* Setting text color to white */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7); /* Adding a subtle glow to the text */
}

.banner-subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #ffffff; /* Setting text color to white */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7); /* Adding a subtle glow to the text */
}

.banner-button {
  display: block;
  width: calc(100% - 40px); /* Full width minus padding */
  max-width: 300px; /* Max width for larger screens */
  margin: 10px auto; /* Centered with margin */
  background-color: transparent; /* Transparent background */
  color: #fff;
  padding: 10px 20px;
  font-size: 1.2rem;
  text-decoration: none;
  border: 2px solid #00879e; /* Border to make the button stand out */
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 165, 0, 0.7); /* Adding a fluorescent glow */
}

.banner-button:hover {
  background-color: #00879e; /* Change background color on hover */
  color: #fff; /* Ensure text color is visible on hover */
  border-color: #ff8c00; /* Change border color on hover */
  box-shadow: 0 0 20px rgba(255, 140, 0, 1); /* Enhance the glow on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .banner-title {
    font-size: 2.5rem;
  }

  .banner-subtitle {
    font-size: 1.2rem;
  }

  .banner-button {
    padding: 8px 16px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .banner-title {
    font-size: 2rem;
  }

  .banner-subtitle {
    font-size: 1rem;
  }

  .banner-button {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}
