/* UserManagement.css */
 @import url('https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&family=Rokkitt&display=swap');
.user-management {
    text-align: center;
    font-family: 'Rokkitt', sans-serif;
    font-size: large;
  }
  
  .user-management h2 {
    margin-bottom: 20px;
    
    color: #998042;
  }
  
  .user-management-row {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    border-top: 2px #998042 solid;
    border-radius: 20px;
  }
  
  .user-management-row a {
    text-decoration: none;
  }
  
  .user-management-card {
    flex: 1;
    margin: 10px;
    padding: 20px;
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: solid 2px black;
    color: black;
    /* background: #d5b768; */
  }
  
  .user-management-card:hover {
    box-shadow: 0 0 10px rgba(213,183,104, 0.9);
    background-color: #fff;
    border: solid 2px #d5b768;
    /* background: linear-gradient(to top right, #d5b768, #1e362c); */
    color: #998042; /* Set text color to white for better visibility */;
  }
  
  /* Remove color styles for individual cards */
  