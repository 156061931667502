.calendar {
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin: 0px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .month-dropdown,
  .year-dropdown {
    padding: 5px;
    font-size: 16px;
  }
  
  .current-time {
    font-size: 14px;
  }
  
  .month {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .date {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .weekdays {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .weekday {
    width: calc(100% / 7);
    text-align: center;
  }
  
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  .day {
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .today {
    background-color: lightblue;
  }
  /* Highlight booked dates */
.day.booked {
    background-color: green; /* Change background color for booked dates */
    color: white; /* Optionally change text color for better contrast */
  }
  
  @media only screen and (max-width: 768px) {
    .calendar {
      padding: 10px;
    }
  
    .month {
      font-size: 20px;
    }
  
    .date {
      font-size: 16px;
    }
  
    .weekday {
      font-size: 14px;
    }
  
    .day {
      padding: 5px;
    }
  }
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 30px;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Hide the modal overflow */
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  }
  
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80%;
    overflow-y: auto; /* Enable scrolling for modal content */
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  .modal-content table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .modal-content th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .modal-content th,
  .modal-content td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .modal-content tbody {
    overflow-y: auto;
    max-height: 400px; /* Example maximum height for scrollbar */
  }
  
  .modal-content tbody::-webkit-scrollbar {
    width: 5px;
  }
  
  .modal-content tbody::-webkit-scrollbar-thumb {
    background-color: #888;
  }
  
  
  .close {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
  }
  